import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { type ReactNode, useState } from "react";
import { Input } from "react-aria-components";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { cn } from "../../utils/classNames.ts";
import { googleTagManagerMutation } from "./googleTagManagerQuery.ts";

export function UpdateGoogleTagManagerKeyModal({ button, value }: { button: ReactNode; value: string }) {
    //#region Hooks

    const updateMutation = useStandardMutation(googleTagManagerMutation);
    const [key, setKey] = useState(value);

    //#endregion

    return (
        <WModal button={button}>
            <WModalContent
                title="Update Google Tag Manager key"
                label="Update"
                modalAction={async () => {
                    await updateMutation.mutateAsync({
                        gtm_key: key,
                    });
                }}
            >
                <WTextField autoFocus label="GTM key" value={key} onChange={setKey}>
                    <Input style={{ width: "240px" }} className={cn(FF.FormFieldInput, TF.Input)} />
                </WTextField>
            </WModalContent>
        </WModal>
    );
}
