import { QueryClient, queryOptions, type QueryKey } from "@tanstack/react-query";
import type { GoogleTagManagerKey } from "@warrenio/api-spec/spec.oats.gen";
import type { ApiClient } from "../../modules/api/apiClient";
import { getResponseData } from "../../modules/api/apiClient";
import { toastOptions } from "../../modules/compute/vmQuery.ts";
import { raiseRequestToast } from "../../modules/notifications/toast.tsx";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";

export const queryKey: QueryKey = ["config/ui/gtm_key"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<GoogleTagManagerKey> => {
            return getResponseData(await client.GET("/config/ui/gtm_key", { signal }));
        },
    });
}

export const googleTagManagerQueryAtom = atomFromStandardQueryOptions(getQuery);

export function googleTagManagerMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["config/ui/gtm_key"],
        async mutationFn({ gtm_key }: GoogleTagManagerKey) {
            return getResponseData(
                await api.PUT("/config/ui/gtm_key", {
                    ...jsonEncodedBody,
                    params: { query: { gtm_key } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Google Tag Manager key updated",
                error: "Failed to update Google Tag Manager key",
            });
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}
